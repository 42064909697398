import React from "react";

const SebikoLogo = ({ className, style }) => (
  <svg
    className={className}
    style={style}
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.749 7L25.504 8.287L28.608 12.088L27.85 12.668L25.884 20.071L23.201 20.94L25.033 13.797L23.027 7.825L23.749 7ZM24.432 35.688L22.848 36.371V23.45L25.274 24.34L27.118 27.047L24.432 35.688ZM16.882 27.047L18.725 24.34L21.15 23.45V36.37L19.566 35.687L16.882 27.047ZM16.237 24.971L14.955 20.846L16.713 21.414L17.259 23.472L16.237 24.971ZM26.74 23.472L27.286 21.414L29.044 20.846L27.762 24.971L26.74 23.472ZM23.262 13.862L21.999 18.786L20.736 13.862L21.999 10.097L23.262 13.862ZM18.494 8.287L20.25 7.001L20.968 7.826L18.965 13.798L20.797 20.941L18.115 20.072L16.148 12.669L15.392 12.089L18.494 8.287ZM7.001 8.605L7 7.804L14.657 13.675L15.585 17.167L12.639 14.229L7.001 8.605ZM10.243 23.005L11.143 17.29L12.108 16.108L15.108 19.098L12.356 18.208L15.015 26.766L12.742 30.106L10.243 23.005ZM17.036 37L16.581 36.783L13.519 31.988L15.661 28.842L17.913 36.089L17.036 37ZM27.419 36.783L26.962 37L26.086 36.09L28.338 28.842L30.481 31.988L27.419 36.783ZM33.758 23.005L31.256 30.107L28.983 26.767L31.643 18.209L28.889 19.099L31.89 16.109L32.856 17.292L33.758 23.005ZM36.999 8.605L30.807 14.78L28.414 17.167L29.34 13.675L37 7.804L36.999 8.605Z"
      fill="currentColor"
    />
  </svg>
);

export default SebikoLogo;
